import { createSlice } from '@reduxjs/toolkit'
import { initialPagination } from 'store/store/store.slice'

import {
	clearStore,
	deleteSingleProduct,
	fetchingProducts,
	newProducts,
} from './products.actions'
import { IProductsInitialState } from './products.interface'

const initialState: IProductsInitialState = {
	products: { data: [] },
	isLoadingProducts: false,
	pagination: initialPagination,
}

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchingProducts.pending, (state) => {
				state.isLoadingProducts = true
			})
			.addCase(fetchingProducts.fulfilled, (state, action) => {
				state.products = action.payload
				state.pagination = action.payload.pagination
				state.isLoadingProducts = false
			})
			.addCase(fetchingProducts.rejected, (state) => {
				state.isLoadingProducts = false
			})
			.addCase(clearStore.pending, (state) => {
				state.isLoadingProducts = true
			})
			.addCase(clearStore.fulfilled, (state) => {
				state.isLoadingProducts = false
				state.products = { data: [] }
			})
			.addCase(clearStore.rejected, (state) => {
				state.isLoadingProducts = false
			})
			.addCase(deleteSingleProduct.pending, (state) => {
				state.isLoadingProducts = true
			})
			.addCase(deleteSingleProduct.fulfilled, (state) => {
				state.isLoadingProducts = false
			})
			.addCase(deleteSingleProduct.rejected, (state) => {
				state.isLoadingProducts = false
			})
			.addCase(newProducts.pending, (state) => {
				state.isLoadingProducts = true
			})
			.addCase(newProducts.fulfilled, (state) => {
				state.isLoadingProducts = false
			})
			.addCase(newProducts.rejected, (state) => {
				state.isLoadingProducts = false
			})
	},
})

export const { actions, reducer } = productsSlice
