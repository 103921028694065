import { Badge } from 'antd'
import cn from 'classnames'
import { FC, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { useSidebarToggler } from '../../../../../hooks/useSidebarToggler'

import './MenuItem.scss'
import { IMenuItem } from './menuItem.interface'

const MenuItem: FC<IMenuItem> = ({
	label,
	path,
	icon,
	title,
	subitems,
	reportLength,
}) => {
	const location = useLocation()
	const { collapse, opensidebar } = useSidebarToggler()

	const [isSubitemShowed, setIsSubItemShowed] = useState(false)
	return (
		<li className={cn('menu-item', collapse ? ' collapsed-menu' : '')}>
			{title && <span>{title}</span>}

			<div
				className={cn(
					'menu-item__box',
					location.pathname === path ? ' active' : ''
				)}
				onClick={() => setIsSubItemShowed(!isSubitemShowed)}
			>
				{subitems ? (
					<a
						className={cn('menu-item__link', collapse ? ' collapse-item' : '')}
						onClick={opensidebar}
					>
						<i className={icon}></i>
						<Badge dot={reportLength !== 0}>
							<span className="menu-item__text">{label}</span>
						</Badge>
						{subitems && (
							<span
								className={
									isSubitemShowed
										? 'subitem-toggler-icon  active'
										: 'subitem-toggler-icon '
								}
							>
								<i className="bx bx-chevron-up"></i>
							</span>
						)}
					</a>
				) : (
					<Link
						to={path || ''}
						className={cn('menu-item__link', collapse ? ' collapse-item' : '')}
					>
						<i className={icon}></i>
						<span className="menu-item__text">{label}</span>
						{subitems ? (
							<span
								className={
									isSubitemShowed
										? 'subitem-toggler-icon  active'
										: 'subitem-toggler-icon '
								}
							>
								<i className="bx bx-chevron-up"></i>
							</span>
						) : null}
					</Link>
				)}
			</div>
			{subitems && (
				<ul className={cn('menu-sub-item ', isSubitemShowed ? ' show' : '')}>
					{subitems.map((item) => (
						<li key={item.path}>
							<Link
								className={cn(location.pathname === item.path ? 'active' : '')}
								to={item.path}
							>
								<Badge dot={item.dot && reportLength !== 0}>{item.label}</Badge>
							</Link>
						</li>
					))}
				</ul>
			)}
		</li>
	)
}
export default MenuItem
