import Cookies from 'js-cookie'

import { IUserState } from '../../store/user/user.interface'

export const saveTokenStorage = (data: string) => {
	Cookies.set('token', data)
	localStorage.setItem('token', data)
}

export const saveToStorage = (response: IUserState) => {
	saveTokenStorage(response.token)
	localStorage.setItem('user', JSON.stringify(response.token))
}

export const removeTokenStorage = () => {
	Cookies.remove('token')
	localStorage.removeItem('user')
}
