import { FC } from 'react'

import { IFieldProps } from './field.interface'

const Field: FC<IFieldProps> = ({ label, ...rest }) => {
	return (
		<label className="w-full flex flex-col mb-2 gap-1">
			<span className="text-gray-600 text-sm">{label}</span>
			<input
				className="px-2 py-1 rounded-md border-2 border-gray-400"
				{...rest}
			/>
		</label>
	)
}
export default Field
