import cn from 'classnames'
import MyPagination from 'components/ui/MyPagination/MyPagination'
import SearchField from 'components/ui/SearchField/SearchField'
import { useDebounce } from 'hooks/useDebounce'
import { FC, useEffect, useState } from 'react'

import Main from '../../../components/layout/main/Main'
import Modal from '../../../components/ui/Modal/Modal'
import Heading from '../../../components/ui/heading/Heading'
import SkeletonCard from '../../../components/ui/skeleton-card/SkeletonCard'
import useActions from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Button } from '../../ui/Button'

import './Home.scss'
import { IHome } from './home.interface'
import StoreProducts from './stores/StoreProducts'
import NewProductForm from './stores/newStore/NewProductForm'
import { productsLoading } from './stores/storeProducts.interface'

const Home: FC<IHome> = () => {
	const { fetchingStores } = useActions()
	const { data, isLoading, pagination } = useTypedSelector(
		(state) => state.store
	)
	const [page, setPage] = useState(
		JSON.parse(localStorage.getItem('store_page'))
	)
	const [pageSize, setPageSize] = useState(15)
	const [search, setSearch] = useState('')
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const debounceSearch = useDebounce(search, 500)
	useEffect(() => {
		fetchingStores({
			page: JSON.parse(localStorage.getItem('store_page')),
			search: debounceSearch,
		})

		// eslint-disable-next-line
	}, [localStorage.getItem('store_page'), page, pageSize, debounceSearch])

	useEffect(() => {
		setModalIsOpen(false)
	}, [isLoading])

	return (
		<div className={cn('home')}>
			<Heading
				title="Магазины"
				icon="bx bx-store"
				color="bg-green-200 text-green-600 shadow-md"
				btns={() => (
					<Button
						onClick={() => setModalIsOpen(true)}
						className="px-4 py-2 text-sm flex items-center gap-x-1"
					>
						<i className="bx bx-plus text-md"></i>Создать новый магазин
					</Button>
				)}
			/>
			<Main className="flex flex-col">
				<SearchField
					placeholder="Введите название магазина или имя учетной записи..."
					onChange={(e) => setSearch(e.target.value)}
				/>

				{isLoading ? (
					<div className="grid gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
						{productsLoading.map((i) => (
							<SkeletonCard key={i} />
						))}
					</div>
				) : (
					<StoreProducts
						data={data}
						page={page}
						search={search}
						setPage={setPage}
						setSearch={setSearch}
					/>
				)}

				<Modal
					onCancel={() => {
						setModalIsOpen(false)
					}}
					onSubmit={() => {}}
					visible={modalIsOpen}
					key={21}
					title="Добавить новый магазин"
					footer={null}
				>
					<NewProductForm />
				</Modal>
				<br />
				<br />
				<MyPagination
					defaultCurrent={JSON.parse(localStorage.getItem('store_page'))}
					total={pagination.total}
					pageSize={pagination.per_page}
					onChange={(page, pageSize) => {
						setPage(page)
						localStorage.setItem('store_page', JSON.stringify(page))
						setPageSize(pageSize)
					}}
				/>
				<br />
			</Main>
		</div>
	)
}
export default Home
