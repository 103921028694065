import { Modal, Popconfirm } from 'antd'
import { Button } from 'components/ui/Button'
import MyPagination from 'components/ui/MyPagination/MyPagination'
import SkeletonImage from 'components/ui/skeleton-image/SkeletonImage'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect, useState } from 'react'

import { productsLoading } from '../storeProducts.interface'

import AddProductForm from './AddProduct/AddProductForm'
import { StoreProductItem } from './StoreProductItem/StoreProductItem'
import './StoreProducts.scss'
import { IStoreProductsProps } from './storeproducts.interface'

const StoreProductsComponent: FC<IStoreProductsProps> = () => {
	const [page, setPage] = useState(1)
	const [datas, setDatas] = useState({})
	const [fileList, setFileList] = useState([])
	const [images, setImages] = useState([])
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const { fetchingProducts, clearStore, newProducts } = useActions()
	const { isLoadingProducts, products, pagination } = useTypedSelector(
		(store) => store.products
	)
	const { singleSelectedItem } = useTypedSelector((state) => state.store)

	const fetchingProductsFunction = () => {
		fetchingProducts({ page: page, store_id: singleSelectedItem?.id })
	}

	const clearImageFields = () => {
		setFileList([])
		setImages([])
	}

	useEffect(() => {
		fetchingProductsFunction()
		clearImageFields()
	}, [page, singleSelectedItem])

	useEffect(() => {
		clearImageFields()
	}, [modalIsOpen])

	const onSubmitFunc = async (data) => {

		const newData = data?.products?.filter(item => !!item)


		newProducts({ data: {...data, products: newData} })
		setModalIsOpen(false)
		setDatas({})
		clearImageFields()
		setTimeout(() => {
			fetchingProductsFunction()
		}, 500)
	}

	return (
		<>
			<div className="flex w-full justify-end mb-4 gap-x-4">
				<Button
					onClick={() => setModalIsOpen(true)}
					className="bg-green-500 hover:bg-green-600"
				>
					<span>
						<i className="bx bx-plus"></i>
					</span>{' '}
					Добавить продукт
				</Button>

				<Popconfirm
					title="Вы хотите удалить все продукты?"
					okText="Да"
					cancelText="Нет"
					onConfirm={() => clearStore({ id: singleSelectedItem.id })}
				>
					<Button
						className="bg-red-500 hover:bg-red-600"
						loading={isLoadingProducts}
					>
						Удаление продуктов
					</Button>
				</Popconfirm>
			</div>

			{isLoadingProducts ? (
				<div className="products-grid">
					{productsLoading.map((x) => (
						<SkeletonImage />
					))}
				</div>
			) : products?.data?.length ? (
				<div className="products-grid">
					{products?.data?.map((item) => (
						<StoreProductItem
							fetchingProductsFunction={fetchingProductsFunction}
							key={item.id}
							{...item}
						/>
					))}
				</div>
			) : (
				<h1 className="my-4 py-20 flex justify-center items-center rounded-md bg-gray-100 text-gray-500 text-xl w-full">
					Товары не найдены!
				</h1>
			)}
			{products.data.length ? (
				<MyPagination
					defaultCurrent={page}
					total={pagination.total}
					pageSize={pagination.per_page}
					onChange={(page) => {
						setPage(page)
					}}
				/>
			) : null}

			<Modal
				title="Загрузить изображения продукта"
				open={modalIsOpen}
				onCancel={() => setModalIsOpen(false)}
				onOk={(e) => onSubmitFunc(datas)}
			>
				<AddProductForm
					fileList={fileList}
					images={images}
					setFileList={setFileList}
					setImages={setImages}
					setDatas={(e) => setDatas(e)}
					open={modalIsOpen}
				/>
			</Modal>
		</>
	)
}
export default StoreProductsComponent
