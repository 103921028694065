import { Input, InputNumber, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Main from 'components/layout/main/Main'
import { Button } from 'components/ui/Button'
import Heading from 'components/ui/heading/Heading'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect, useState } from 'react'

import { IAdminstration } from './adminstration.interface'

const Administration: FC<IAdminstration> = () => {
	const { getAdminstrationData, updateAdminstrationData } = useActions()
	const { data, isLoading, isUpdateLoading } = useTypedSelector(
		(store) => store.adminstration
	)
	const [updatedData, setUpdatedData] = useState({
		description: '',
		title: '',
		price: null,
		card_number: '',
		card_holder: '',
		end_text: '',
		phone: '',
		block_text: '',
		unblock_text: '',
		info_text: ''
	})
	useEffect(() => {
		getAdminstrationData()
	}, [])

	useEffect(() => {
		setUpdatedData(data.data)
	}, [data])

	const onChange = (e) => {
		setUpdatedData({ ...updatedData, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		updateAdminstrationData({ data: updatedData })
	}

	return (
		<Spin spinning={isLoading}>
			<Heading
				icon="bx bx-receipt"
				title="Админстрация"
				color="bg-cyan-300 text-cyan-600"
			/>
			<Main>
				<form onSubmit={onSubmit} className="flex gap-4 flex-col lg:flex-row ">
					<div className="flex-1">
						<fieldset className="mb-4 flex-1">
							<legend className="mb-4 text-gray-600">
								Тексты в разделе блока
							</legend>
							<TextArea
								required
								name="block_text"
								onChange={onChange}
								value={updatedData.block_text}
								className="mb-2"
								placeholder="Для основного текста"
							/>
							{/* <Input className="mb-2" placeholder="Текст на кнопке" /> */}
							{/* <Input placeholder="Текст в номере" /> */}
						</fieldset>

						<fieldset className="mb-4 flex-1">
							<legend className="mb-4 text-gray-600">
								Раздел при разблокировке
							</legend>
							<TextArea
								required
								onChange={onChange}
								value={updatedData.unblock_text}
								name="unblock_text"
								className="mb-2"
								placeholder="Для основного текста"
							/>
						</fieldset>
						<fieldset className="mb-4 flex-1">
							<legend className="mb-4 text-gray-600">
								Информационный текст
							</legend>
							<TextArea
								required
								onChange={onChange}
								value={updatedData.info_text}
								name="info_text"
								className="mb-2"
								placeholder="Информационный текст"
							/>
						</fieldset>
					</div>

					<fieldset className="flex-1">
						<legend className="mb-4 text-gray-600">
							Тексты в разделе регистрации
						</legend>
						<TextArea
							required
							onChange={onChange}
							name="description"
							value={updatedData.description}
							className="mb-2"
							placeholder="Для oписание"
						/>
						<InputNumber
							required
							onChange={(e) => setUpdatedData({ ...updatedData, price: e })}
							name="price"
							value={updatedData.price}
							className="mb-2 w-full"
							placeholder="Сумма"
							decimalSeparator=""
						/>
						<InputNumber
							required
							className="mb-2 w-full"
							placeholder="Номер карты"
							decimalSeparator=""
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
							}
							maxLength={19}
							onChange={(e) =>
								setUpdatedData({ ...updatedData, card_number: e })
							}
							name="card_number"
							value={updatedData.card_number}
						/>
						<Input
							required
							onChange={onChange}
							name="card_holder"
							value={updatedData.card_holder}
							className="mb-2"
							placeholder="Имя владельца карты"
						/>
						<TextArea
							required
							value={updatedData.end_text}
							onChange={onChange}
							name="end_text"
							className="mb-2"
							placeholder="Заключительный текст"
						/>
						<Input
							required
							onChange={onChange}
							name="phone"
							className="mb-2 w-full"
							placeholder="Номер телефона"
							value={updatedData.phone}
						/>
						<div className="flex justify-end">
							<Button
								loading={isUpdateLoading}
								className="bg-green-500 hover:bg-green-600"
							>
								Сохранять
							</Button>
						</div>
					</fieldset>
				</form>
			</Main>
		</Spin>
	)
}
export default Administration
