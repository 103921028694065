import { Button } from 'components/ui/Button'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC } from 'react'

import './StoreProductItem.scss'
import { IStoreProductItemProps } from './storeProductItem.interface'

export const StoreProductItem: FC<IStoreProductItemProps> = (props) => {
	const { image, id } = props

	const { deleteSingleProduct } = useActions()
	return (
		<div className="bg-gray-200 rounded-md store-product">
			<img src={image} alt="" />
			<div className="store-product__overlay">
				<Button
					onClick={() => {
						deleteSingleProduct({ id })
						setTimeout(() => {
							props.fetchingProductsFunction()
						}, 300)
					}}
					className="bg-red-500 hover:bg-red-600 focus:outline-red-500"
				>
					<span>
						<i className="bx bx-trash"></i>
					</span>{' '}
					Удалить
				</Button>
			</div>
		</div>
	)
}
