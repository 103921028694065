import { InboxOutlined } from '@ant-design/icons'
import { Spin, Upload } from 'antd'
import { Button } from 'components/ui/Button'
import { useTypedSelector } from 'hooks/useTypedSelector'
import React, { useState } from 'react'
import { addWatermark } from 'utils/addWatermark'
import { uploadImage } from 'utils/uploadImage'

const { Dragger } = Upload
interface IAddProductsFormProps {
	open?: boolean
	setDatas?: (data: any) => void
	setFileList?: (data: any) => void
	setImages?: (data: any) => void
	fileList?: any
	images?: any
}
const AddProductForm: React.FC<IAddProductsFormProps> = ({
	open,
	setDatas,
	setImages,
	setFileList,
	fileList,
	images,
}) => {
	const [uploading, setUploading] = useState(false)
	const { singleSelectedItem } = useTypedSelector((store) => store.store)

	const onUploading = () => {
		setUploading(true)
		uploadImage(fileList.slice(0, 10), singleSelectedItem.id).then((res) => {
			setImages(res.data)
			setUploading(false)
			addWatermark(res.data[0].url, singleSelectedItem.image)

			const data = {
				store_id: singleSelectedItem.id,
				products: res.data.map((item, index) => {
					if (!item.url.includes('watermark')) {
						return {
							name: 'name',
							image: item.url,
							watermark_image: res.data[index + 1].url,
						}
					}
				}),
			}
			setDatas(data)
		})
	}

	console.log(singleSelectedItem)

	return !fileList.length ? (
		<>
			<Dragger
				showUploadList={false}
				beforeUpload={() => false}
				onChange={(e) => {
					setFileList((prev) => [...prev, e.file])
				}}
				multiple
				maxCount={10}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Выберите изображения из вашей установки <br /> или загрузите их здесь
				</p>
				<p className="ant-upload-hint">
					Вы можете загрузить не более 10 изображений
				</p>
			</Dragger>
		</>
	) : (
		<Spin spinning={uploading}>
			<div className="w-full py-12 gap-x-4 rounded-md border-2 border-blue-400 flex items-center justify-center">
				<Button onClick={onUploading}>Загрузить</Button>
				<Button
					onClick={() => {
						setFileList([])
						setImages([])
					}}
					className="bg-yellow-500 hover:bg-yellow-600"
				>
					Отмена
				</Button>
			</div>
			<div className="grid grid-cols-2 my-4">
				{images.map((item) => {
					if (!item.url.includes('watermark')) {
						return (
							<div className="relative border-2 mb-4" id="image-with-logo">
								<img className="z-10" key={item.id} src={item.url} alt="" />
							</div>
						)
					}
				})}
			</div>
		</Spin>
	)
}

export default AddProductForm
