import {axiosAPI} from 'api/axios.interceptors'

export const ReportsService = {
	async getAll({ page }) {
		return await axiosAPI.get('/api/reports', {
			params: {
				page,
				status: 'pending',
			},
		})
	},

	async approve({ id }: { id: number }) {
		const response = await axiosAPI.post(`/api/reports/approveReport`, {
			report_id: id,
		})

		return response
	},
	async decline({ id }: { id: number }) {
		const response = await axiosAPI.post(`/api/reports/declinedReport`, {
			report_id: id,
		})

		return response
	},
}
