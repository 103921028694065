export const getUserStatusColor = (status) => {
	if (status === 'pending') {
		return 'gold'
	} else if (status === 'active') {
		return 'green'
	} else if (status === 'inactive') {
		return 'cyan'
	} else {
		return 'red'
	}
}

export const getUserStatusText = (status) => {
	if (status === 'pending') {
		return 'Ожидание'
	} else if (status === 'active') {
		return 'Активный'
	} else if (status === 'inactive') {
		return 'Не активный'
	} else {
		return 'Отказано'
	}
}
