import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { IStoreData } from '../../../../shared/types/store.types'

import './StoreItem.scss'

const StoreItem: FC<IStoreData> = (data) => {
	const { name, phone, description, instagram, telegram, image: img, id } = data

	return (
		<div className="product__card">
			<Link to={`/store/profile/${id}`}>
				<div id="imageStore" className="store-img flex items-center">
					<img src={img} alt="Store_image" height={250} />
				</div>
			</Link>
			<div className="card__details">
				<h2>{name}</h2>
				<p className="mb-6">{description}</p>

				<div className="card__details-icons ">
					{instagram && (
						<a href={instagram}>
							<i className="bx bxl-instagram"></i>
						</a>
					)}
					{telegram && (
						<a href={telegram}>
							<i className="bx bxl-telegram"></i>
						</a>
					)}
				</div>

				<div className="flex gap-x-1 items-center mt-4 text-xs font-semibold text-gray-500 absolute bottom-3">
					<i className="bx bx-phone text-sm"></i>
					<span>{phone}</span>
				</div>
			</div>
		</div>
	)
}
export default StoreItem
