import { Image, Skeleton, Table } from 'antd'
import useActions from 'hooks/useActions'
import { FC } from 'react'

import { IReportsTableProps } from './reportsTable.interface'

const ReportsTable: FC<IReportsTableProps> = ({
	data,
	isLoading = false,
	...rest
}) => {
	const { approveReport, declineReport } = useActions()
	let dataSource = []

	data.map((item) => {
		dataSource.push({
			key: item.id,
			image: (
				<Image
					width={50}
					src={
						item.image
					}
				/>
			),
			name: (
				<>
					<h3>{item.name}</h3>
					<h4>{item.phone}</h4>
				</>
			),
			date: item.created_at,
			actions: (
				<div className="flex gap-x-4">
					<button
						onClick={() => approveReport({ id: item.id })}
						className="btn-primary px-2 text-xl py-1"
					>
						<i className="bx bx-check-circle"></i>
					</button>
					<button
						onClick={() => {
							declineReport({ id: item.id })
						}}
						className="bg-red-500 btn-primary px-2 text-xl py-1  hover:bg-red-600"
					>
						<i className="bx bx-x-circle"></i>
					</button>
				</div>
			),
		})
	})
	let columns = [
		{
			title: 'Скриншот',
			dataIndex: 'image',
			key: 'image',
			width: '100px',
		},
		{
			title: 'Клиент',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Дата',
			dataIndex: 'date',
			key: 'date',
			width: '60%',
		},
		{
			title: 'Действия',
			dataIndex: 'actions',
			key: 'actions',
		},
	]

	return (
		<Skeleton loading={isLoading} active>
			<Table
				columns={columns}
				dataSource={dataSource}
				pagination={false}
				{...rest}
			/>
		</Skeleton>
	)
}
export default ReportsTable
