import { FC } from 'react'

import StoreItem from './StoreItem'
import { IStoreParams } from './storeProducts.interface'

const StoreProducts: FC<IStoreParams> = ({
	data,
	page,
	search,
	setPage,
	setSearch,
}) => {
	if (data.length === 0)
		return (
			<h1 className="h-48 w-full rounded-md flex flex-col gap-2 items-center justify-center bg-gray-100 text-xl text-gray-300">
				<i className="bx bx-box text-6xl text-gray-300"></i>
				Магазинов не найдено!
			</h1>
		)
	return (
		<div className="grid gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
			{data?.map((item) => (
				<StoreItem key={item.id} {...item} />
			))}
		</div>
	)
}
export default StoreProducts
