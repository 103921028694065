import { createSlice } from '@reduxjs/toolkit'

import {
	addNewStore,
	fetchingStores,
	getSingleStore,
	updateStore,
} from './store.actions'
import { IStoreInitialState } from './store.interface'

export const initialPagination = {
	count: 15,
	current_page: 1,
	per_page: 15,
	total: 30,
	total_pages: 2,
}
const initialState: IStoreInitialState = {
	data: [],
	pagination: initialPagination,
	isLoading: false,
	search: '',
	page: 1,
	loadingSingle: false,
	singleSelectedItem: null,
}

export const storeSlice = createSlice({
	name: 'store',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchingStores.pending, (state) => {
				state.isLoading = true
			})
			.addCase(fetchingStores.fulfilled, (state, action) => {
				state.isLoading = false
				state.data = action.payload.data
				state.pagination = action.payload.pagination
			})
			.addCase(fetchingStores.rejected, (state) => {
				state.isLoading = false
				state.data = []
				state.pagination = initialPagination
			})
			.addCase(addNewStore.pending, (state) => {
				state.isLoading = true
			})
			.addCase(addNewStore.fulfilled, (state) => {
				state.isLoading = false
			})
			.addCase(addNewStore.rejected, (state) => {
				state.isLoading = false
			})

			.addCase(updateStore.pending, (state) => {
				state.isLoading = true
			})
			.addCase(updateStore.fulfilled, (state) => {
				state.isLoading = false
			})
			.addCase(updateStore.rejected, (state) => {
				state.isLoading = false
			})

			.addCase(getSingleStore.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getSingleStore.fulfilled, (state, action) => {
				state.singleSelectedItem = action.payload.data.data
				state.isLoading = false
			})
			.addCase(getSingleStore.rejected, (state) => {
				state.isLoading = false
			})
	},
})

const { reducer, actions } = storeSlice
export const storeReducer = reducer
export const storeActions = actions
