import {IUpdateStoreRequestData} from 'store/store/store.interface'

import {axiosAPI} from '../../api/axios.interceptors'
import {StoreRequestParams} from '../../shared/types/store.types'

export const StoreService = {
	async fetchStores({ page, search }: StoreRequestParams) {
		const response = await axiosAPI.get('api/stores', {
			params: {
				search: search,
				page: page,
			},
		})
		return response.data
	},

	async newStore(data) {
		return await axiosAPI.post('/api/stores', data)
	},

	async updateStore(data: IUpdateStoreRequestData) {
		return await axiosAPI.patch('api/stores', data)
	},

	async getSingleStore(id: number) {
		return await axiosAPI.get(`/api/stores/${id}`)
	},
}
