import { FC, useState } from 'react'

import './SearchField.scss'

interface ISearchFieldProps extends React.ComponentProps<'input'> {}

const SearchField: FC<ISearchFieldProps> = ({ className, ...rest }) => {
	const [labelClassNames, setLabelClassNames] = useState('')
	return (
		<label
			className={`search-field w-full px-4 py-2 rounded-md bg-gray-100 flex items-center  gap-x-4 mb-4 ${labelClassNames}`}
		>
			<i className="bx bx-search text-2xl text-gray-400"></i>
			<input
				onFocus={(e) => setLabelClassNames('active')}
				onBlur={(e) => setLabelClassNames('')}
				type="text"
				className={
					className +
					' search-field__input py-2 w-full bg-transparent placeholder-gray-300 text-lg outline-none'
				}
				{...rest}
			/>
		</label>
	)
}
export default SearchField
