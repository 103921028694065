import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	collapse: window.innerWidth < 1000 ? true : false,
}

export const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		sidebarToggler: (state) => {
			state.collapse = !state.collapse
		},
		openSidebar: (state) => {
			state.collapse = false
		},
		closeSidebar: (state) => {
			state.collapse = true
		},
	},
})

const { actions, reducer } = sidebarSlice
export const { sidebarToggler, openSidebar, closeSidebar } = actions
export const sidebarReducer = reducer
