import { FaBars } from '@react-icons/all-files/fa/FaBars'
import { FC } from 'react'

import { useSidebarToggler } from '../../../../hooks/useSidebarToggler'

import './Logo.scss'

const image = require('../../../../assets/logo.png')
const Logo: FC<{ classNames?: string }> = ({ classNames }) => {
	const { toggleSidebar } = useSidebarToggler()
	return (
		<div className={`sidebar__header ${classNames}`}>
			<div className="sidebar__logo">
				<img width={40} src={image} alt="" />
				<h2>Raytel</h2>
			</div>

			<button className="menu-toggler" onClick={toggleSidebar}>
				<FaBars />
			</button>
		</div>
	)
}
export default Logo
