import cn from 'classnames'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect } from 'react'

import { useSidebarToggler } from '../../../hooks/useSidebarToggler'

import MenuContainer from './MenuContainer/MenuContainer'
import './Sidebar.scss'
import Logo from './logo/Logo'
import { ISidebar } from './sidebar.interface'

const Sidebar: FC<ISidebar> = () => {
	const { collapse } = useSidebarToggler()
	const { getAllReports } = useActions()
	const { data } = useTypedSelector((store) => store.reports)

	useEffect(() => {
		getAllReports({ page: 1 })
	}, [])

	return (
		<div className={cn('sidebar', collapse ? 'collapsed' : null)}>
			<Logo />
			<MenuContainer reportLength={data.length} />
		</div>
	)
}
export default Sidebar
