import { axiosAPI } from 'api/axios.interceptors'

export const uploadImage: (files: any[], store_id?: number) => Promise<any> = async (files, store_id) => {
	const formData = new FormData()
	files.forEach((item, index) => {
		formData.append(`file[${index}]`, item)
	})
	console.log(store_id)
	if (store_id) {
		formData.append('store_id', store_id?.toString())
	}
	const response = await axiosAPI.post('/api/files',
		formData)
	return response.data
}


