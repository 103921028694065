import * as adminstrationActions from './adminstration/adminstration.actions'
import * as productsAction from './products/products.actions'
import * as reportsActions from './reports/reports.actions'
import * as storeActions from './store/store.actions'
import * as userActions from './user/user.actions'

export const allActions = {
	...userActions,
	...storeActions,
	...productsAction,
	...reportsActions,
	...adminstrationActions,
}
