import { Pagination } from 'antd'
import Main from 'components/layout/main/Main'
import MyPagination from 'components/ui/MyPagination/MyPagination'
import Heading from 'components/ui/heading/Heading'
import ReportsTable from 'components/ui/reportsTable/ReportsTable'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect, useState } from 'react'

const Reports: FC = () => {
	const [page, setPage] = useState<number>(1)
	const { getAllReports } = useActions()
	const { data, isLoading, pagination } = useTypedSelector(
		(store) => store.reports
	)
	useEffect(() => {
		getAllReports({ page: page })
	}, [page])

	return (
		<div>
			<Heading
				icon="bx bx-user"
				title="Клиенты под вопросом"
				color="bg-yellow-300 text-yellow-600"
			/>
			<Main>
				<ReportsTable isLoading={isLoading} data={data} />
				<br />
				<br />
				<MyPagination
					defaultCurrent={page}
					total={pagination.total}
					pageSize={pagination.per_page}
					onChange={(page) => {
						setPage(page)
					}}
				/>
				<br />
			</Main>
		</div>
	)
}
export default Reports
