import { FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import store from '../store/store'

const MyProviders: FC<{ children: JSX.Element }> = ({ children }) => {
	const queryClient = new QueryClient({})

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ToastContainer />
				<BrowserRouter>{children}</BrowserRouter>
			</Provider>
		</QueryClientProvider>
	)
}
export default MyProviders
