import './SkeletonImage.scss'

const SkeletonImage = () => {
	return (
		<div>
			<div className="card is-loading">
				<div className="image"></div>
			</div>
		</div>
	)
}
export default SkeletonImage
