import { Input } from 'antd'
import { FC, useState } from 'react'
import { useEffect } from 'react'

import useActions from '../../../hooks/useActions'
import { useAuth } from '../../../hooks/useAuth'
import { Button } from '../../ui/Button'
import Field from '../../ui/form-elements/Field'

import './Login.scss'
import { useAuthRedirect } from './hooks/useAuthRedirect'
import { ILogin } from './login.interface'

const logo = require('../../../assets/logo.png')
const Login: FC = () => {
	const { checkAuth, login } = useActions()
	useAuthRedirect()
	const [password, setPassword] = useState('')
	const [phone, setPhone] = useState('')

	const { isLoading } = useAuth()

	useEffect(() => {
		checkAuth()
	}, [])

	const onSubmit = (data: ILogin) => {
		login(data)
	}

	return (
		<div className={'login'}>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit({ phone, password })
				}}
				className="login-form"
			>
				<div className="flex w-full items-center justify-center gap-x-2 mb-6">
					<img src={logo} alt="" width={50} />
					<h3 className="text-3xl">Raytel</h3>
				</div>
				<Input
					className="form-input mb-4"
					onChange={(e) => setPhone(e.target.value)}
					placeholder="Номер телефона"
					required
				/>
				<Input.Password
					className="form-input mb-6"
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Ваш пароль"
					required
				/>
				<Button className="block w-full" loading={isLoading}>
					Войти
				</Button>
			</form>
		</div>
	)
}
export default Login
