import { createSlice } from '@reduxjs/toolkit'
import { initialPagination } from 'store/store/store.slice'

import { approveReport, declineReport, getAllReports } from './reports.actions'
import { IReportsInitialState } from './reports.interface'

const initialState: IReportsInitialState = {
	data: [],
	isLoading: false,
	pagination: initialPagination,
}

export const reportsSlice = createSlice({
	name: 'reports',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllReports.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getAllReports.fulfilled, (state, action) => {
				state.data = action.payload.data.data
				state.pagination = action.payload.data.pagination
				state.isLoading = false
			})
			.addCase(getAllReports.rejected, (state) => {
				state.data = []
				state.pagination = initialPagination
				state.isLoading = false
			})
			.addCase(approveReport.pending, (state) => {
				state.isLoading = true
			})
			.addCase(approveReport.fulfilled, (state, action) => {
				state.isLoading = false
			})
			.addCase(approveReport.rejected, (state) => {
				state.isLoading = false
			})
			.addCase(declineReport.pending, (state) => {
				state.isLoading = true
			})
			.addCase(declineReport.fulfilled, (state, action) => {
				state.isLoading = false
			})
			.addCase(declineReport.rejected, (state) => {
				state.isLoading = false
			})
	},
})

export const { actions, reducer } = reportsSlice
