import { FC } from 'react'

import './Main.scss'

interface MainProps extends React.ComponentProps<'div'> {
	children: any
}
const Main: FC<MainProps> = ({ children }) => {
	return <div className="main-card overflow-x-auto">{children}</div>
}
export default Main
