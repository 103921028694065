import { createSlice } from '@reduxjs/toolkit'

import {
	getAdminstrationData,
	updateAdminstrationData,
} from './adminstration.actions'

const initialState = {
	data: {
		data: {
			description: 'TEST 3',
			title: 'titleeeeee',
			price: 99000,
			card_number: '0000',
			card_holder: 'MEN',
			end_text: 'end text',
			phone: '+9999999',
			block_text: 'block text',
			unblock_text: 'unblock',
			info_text: ''
		},
	},
	isLoading: false,
	isUpdateLoading: false,
}
export const adminstrationSlice = createSlice({
	name: 'adminstration',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAdminstrationData.pending, (state) => {
				state.isLoading = true
			})
			.addCase(getAdminstrationData.fulfilled, (state, action) => {
				state.isLoading = false
				state.data = action.payload.data
			})
			.addCase(getAdminstrationData.rejected, (state) => {
				state.isLoading = false
			})
			.addCase(updateAdminstrationData.pending, (state) => {
				state.isUpdateLoading = true
			})
			.addCase(updateAdminstrationData.fulfilled, (state, action) => {
				state.isUpdateLoading = false
			})
			.addCase(updateAdminstrationData.rejected, (state) => {
				state.isUpdateLoading = false
			})
	},
})

export const { actions, reducer } = adminstrationSlice
