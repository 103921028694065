import { reducer as toastrReducer } from 'react-redux-toastr'

import { storeReducer } from '../store/store/store.slice'

import { reducer as adminstrationReducer } from './adminstration/adminstration.slice'
import { reducer as productsReducer } from './products/products.slice'
import { reducer as reportsReducer } from './reports/reports.slice'
import { sidebarReducer } from './sidebar/sidebar.slice'
import { reducer as userReducer } from './user/user.slice'

export const rootReducer = {
	toastr: toastrReducer,
	user: userReducer,
	sidebar: sidebarReducer,
	store: storeReducer,
	products: productsReducer,
	reports: reportsReducer,
	adminstration: adminstrationReducer,
}
