import { createSlice } from '@reduxjs/toolkit'
import { IUsersData } from 'shared/types/users.interface'
import { initialPagination } from 'store/store/store.slice'

import { getStoreLocal } from '../../utils/getStoreLocal'

import { checkAuth, getAllClients, login, logout } from './user.actions'
import { IInitialState } from './user.interface'

const initialState: IInitialState = {
	isLoading: false,
	user: getStoreLocal('user'),
	users: [],
	pagination: initialPagination,
	isLoadingUsers: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.isLoading = true
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isLoading = false
				state.user = action.payload
				window.location.reload()
			})
			.addCase(login.rejected, (state) => {
				state.isLoading = false
				state.user = null
			})

			.addCase(logout.fulfilled, (state, action) => {
				state.isLoading = false
				state.user = null
			})

			.addCase(checkAuth.pending, (state, action) => {
				state.isLoading = true
			})

			.addCase(checkAuth.fulfilled, (state, action) => {
				state.isLoading = false
			})

			.addCase(checkAuth.rejected, (state, action) => {
				state.isLoading = false
				state.user = null
			})
			.addCase(getAllClients.pending, (state) => {
				state.isLoadingUsers = true
			})
			.addCase(getAllClients.fulfilled, (state, action) => {
				state.isLoadingUsers = false
				state.users = action.payload.data.data
				state.pagination = action.payload.data.pagination
			})
			.addCase(getAllClients.rejected, (state) => {
				state.isLoadingUsers = false
			})
	},
})

export const { actions, reducer } = userSlice
