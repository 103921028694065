import { Image, Skeleton, Table, TableColumnsType, Tag } from 'antd'
import { FC } from 'react'
import { IUsersData } from 'shared/types/users.interface'
import {getUserStatusColor, getUserStatusText} from 'utils/getUserStatusColor'

import { Button } from '../Button'

import { IUsersTableProps } from './usersTable.interface'

const UsersTable: FC<IUsersTableProps> = ({
	data,
	usersType = 'all',
	isLoading,
	...rest
}) => {
	let dataSource = []
	data.map((item) => {
		dataSource.push({
			key: item.id,
			name: (
				<>
					<h3>{item.name}</h3>
					<h4>{item.phone}</h4>
				</>
			),
			status: <Tag color={getUserStatusColor(item.status)}>{getUserStatusText(item.status)}</Tag>,
			deadline: item.deadline || '--.--.---- --:--',
			actions: (
				<div>
					<Button>Подтверждение</Button>
					<Button className="bg-red-500 hover:bg-red-600">Отмена</Button>
				</div>
			),
		})
	})

	let columns = [
		{
			key: 'key',
			title: 'Клиент',
			dataIndex: 'name',
			width: '20%',
		},
		{
			key: 'key',
			title: 'Статус',
			dataIndex: 'status',
			width: '10%',
		},
		{
			key: 'deadline',
			title: 'Срок',
			dataIndex: 'deadline',
		},
	]

	return (
		<Skeleton loading={isLoading} active>
			<Table
				columns={columns}
				dataSource={dataSource}
				pagination={false}
				{...rest}
			/>
		</Skeleton>
	)
}
export default UsersTable
