import React from 'react'
import ReduxToastr, { toastr } from 'react-redux-toastr'

import RoutesPage from './components/routes/RoutesPage'
import { Button } from './components/ui/Button'

function App() {
	return (
		<div className="App">
			<ReduxToastr timeOut={500} />
			<RoutesPage />
		</div>
	)
}

export default App
