import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import './index.scss'
import MyProviders from './providers/MyProviders'

console.error = () => {}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<MyProviders>
		<App />
	</MyProviders>
)
