import { IStoreData } from '../../../../shared/types/store.types'

export interface IStoreParams {
	data: IStoreData[]
	page: number
	setPage: (page: number) => void
	search: string
	setSearch: (search: string) => void
}

export const productsLoading = [1, 2, 3, 4, 5, 6, 7, 8]
