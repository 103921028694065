import NotFoundPage from 'components/screens/404/NotFoundPage'
import StoreProfile from 'components/screens/home/stores/storeProfile/StoreProfile'
import Reports from 'components/screens/users/Reports/Reports'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import MyLayout from '../layout/MyLayout'
import Administration from '../screens/adminstration/Administration'
import Home from '../screens/home/Home'
import Login from '../screens/login/Login'
import Users from '../screens/users/AllUsers/Users'

import AuthRequire from './authRequire'

const RoutesPage: FC = () => {
	return (
		<Routes>
			<Route path="/" element={<AuthRequire />}>
				<Route path="/" element={<MyLayout />}>
					<Route path="/" element={<Home />} />
					<Route path="/store/profile/:id" element={<StoreProfile />} />
					<Route path="/users/all" element={<Users />} />
					<Route path="/users/request" element={<Reports />} />
					<Route path="/administrator" element={<Administration />} />
				</Route>
			</Route>
			<Route path="/auth" element={<Login />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	)
}
export default RoutesPage
