import { axiosAPI } from 'api/axios.interceptors'

export const AdminstrationService = {
	async getData() {
		const response = await axiosAPI.get('/api/settings')
		return response
	},

	async updateData(data) {
		const response = await axiosAPI.patch('/api/settings', data)
		return response
	},
}
