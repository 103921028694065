import { Spin, Switch } from 'antd'
import Main from 'components/layout/main/Main'
import { Button } from 'components/ui/Button'
import Field from 'components/ui/Field/Field'
import Heading from 'components/ui/heading/Heading'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import { uploadImage } from 'utils/uploadImage'

import StoreProductsComponent from '../storeProducts/StoreProducts'

import './StoreProfile.scss'
import { IStoreProfileProps } from './storeProfile.interface'

const StoreProfile: FC<IStoreProfileProps> = () => {
	const { singleSelectedItem, isLoading } = useTypedSelector(
		(state) => state.store
	)
	const { updateStore, getSingleStore } = useActions()
	const params = useParams()
	useEffect(() => {
		getSingleStore({ id: +params.id })
		// eslint-disable-next-line
	}, [params.id])

	const [updateStoreData, setUpdateStoreData] = useState({
		created_at: null,
		store_id: null,
		name: '',
		phone: '',
		description: '',
		image: '',
		instagram: '',
		telegram: '',
		active: null,
	})
	// get default values
	useEffect(() => {
		if (singleSelectedItem) {
			setUpdateStoreData({
				created_at: singleSelectedItem.created_at,
				store_id: singleSelectedItem.id,
				name: singleSelectedItem.name,
				phone: singleSelectedItem.phone,
				description: singleSelectedItem.description,
				image: singleSelectedItem.image,
				instagram: singleSelectedItem.instagram,
				telegram: singleSelectedItem.telegram,
				active: singleSelectedItem.active,
			})
		}
	}, [singleSelectedItem])

	// change update data states
	const onChange = (e) => {
		setUpdateStoreData({ ...updateStoreData, [e.target.name]: e.target.value })
	}

	// submit
	const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()
		updateStore(updateStoreData)
	}

	// upload image
	const storeImageUpload = async (e) => {
		const response = await uploadImage([...e.target.files])
		setUpdateStoreData({ ...updateStoreData, image: response.data[0].url })
	}

	if (!params.id) return <Navigate to={'/'} replace />
	return (
		<div>
			<Heading
				icon={'bx bx-store'}
				title={singleSelectedItem?.name}
				color="bg-yellow-200 text-yellow-500"
				btns={() => (
					<div className="flex gap-x-2 items-center">
						<Switch
							checkedChildren="Активный"
							unCheckedChildren="Не активен"
							defaultChecked={singleSelectedItem?.active}
							checked={updateStoreData.active}
							onChange={(e) =>
								setUpdateStoreData({ ...updateStoreData, active: e })
							}
						/>
						<Link to={'/'} className="btn-primary px-6 py-2 text-sm">
							Отмена
						</Link>
					</div>
				)}
			/>
			<Main>
				<Spin spinning={isLoading}>
					<div className="flex items-start gap-x-4 profile flex-col lg:flex-row ">
						<div className="store-profile__img bg-gray-200">
							<img src={updateStoreData.image} alt="" />
						</div>

						<div className="profile__details flex-col lg:flex-row ">
							<form onSubmit={onSubmit} className="profile__details-form">
								<Field
									value={updateStoreData.name}
									onChange={onChange}
									name="name"
									type="text"
									required
									label="Название магазина"
								/>
								<Field
									value={updateStoreData.phone}
									onChange={onChange}
									name="phone"
									type="tel"
									required
									label="Номер телефона"
								/>
								<Field
									type="file"
									label="Логотип магазина"
									onChange={(e) => storeImageUpload(e)}
								/>

								<Field
									value={updateStoreData.instagram}
									onChange={onChange}
									name="instagram"
									type="url"
									label="Instagram"
								/>
								<Field
									value={updateStoreData.telegram}
									onChange={onChange}
									name="telegram"
									type="url"
									label="Telegram"
								/>
								<label>
									<span>Описание</span>
									<textarea
										value={updateStoreData.description}
										onChange={onChange}
										name="description"
									></textarea>
								</label>
								<label>
									<input type="hidden" />
								</label>
								<div className="grid grid-cols-2 gap-x-4">
									<div></div>
									<div>
										<Button
											className="w-full  bg-green-500 hover:bg-green-600"
											loading={isLoading}
										>
											Сохранить
										</Button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Spin>
			</Main>
			<Main>
				<StoreProductsComponent />
			</Main>
		</div>
	)
}
export default StoreProfile
