import Cookies from 'js-cookie'

import { axiosClassic } from '../../api/axios.interceptors'
import { IResponseLogin } from '../../store/user/user.interface'

import { removeTokenStorage, saveToStorage } from './auth.helper'

export const AuthService = {
	async login(phone: string, password: string) {
		const response = await axiosClassic.post<{ name: string; token: string }>(
			'/api/signIn/admin',
			{
				phone,
				password,
			}
		)

		if (response.data.token) {
			saveToStorage(response.data)
		}

		return response
	},
	async check() {
		const token = Cookies.get('token')
		const response = await axiosClassic.get<IResponseLogin>('/api/users', {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})

		return response
	},
	async logout() {
		removeTokenStorage()
	},
}
