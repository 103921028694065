import cn from 'classnames'
import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import useActions from '../../hooks/useActions'
import { useSidebarToggler } from '../../hooks/useSidebarToggler'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import './MyLayout.scss'
import { ILayout } from './layout.interface'
import Sidebar from './sidebar/Sidebar'
import Logo from './sidebar/logo/Logo'

const MyLayout: FC<ILayout> = () => {
	const { collapse } = useSidebarToggler()

	const { checkAuth } = useActions()
	useEffect(() => {
		checkAuth()
	}, [])
	return (
		<div className="layout">
			<Sidebar />
			<div className={cn('main', collapse && ' sidebar-collapsed')}>
				<div className="mobile-head">
					<Logo classNames="px-4 py-2 bg-white mb-4 shadow-sm rounded-md" />
				</div>
				<Outlet />
			</div>
		</div>
	)
}
export default MyLayout
