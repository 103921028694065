import { FC } from 'react'

import { IButton } from './Button.interface'

const buttonLoading = require('../../assets/loading.png')

export const Button: FC<IButton> = ({
	className,
	children,
	loading,
	...props
}) => {
	return (
		<button
			{...props}
			className={
				className + ' btn-primary px-6 py-2 flex justify-center gap-x-2'
			}
			disabled={loading}
		>
			<img
				width={25}
				src={buttonLoading}
				alt="Loading"
				className={`transition duration-150 ease-linear animate-spin ${
					loading ? '' : 'transform scale-0 hidden '
				} `}
			/>
			{children}
		</button>
	)
}
