import { useDispatch } from 'react-redux'

import {
	closeSidebar,
	openSidebar,
	sidebarToggler,
} from '../store/sidebar/sidebar.slice'

import { useTypedSelector } from './useTypedSelector'

export const useSidebarToggler = () => {
	const { collapse } = useTypedSelector((state) => state.sidebar)
	const dispatch = useDispatch()
	const toggleSidebar = () => dispatch(sidebarToggler())
	const opensidebar = () => dispatch(openSidebar())
	const closesidebar = () => dispatch(closeSidebar())
	return {
		toggleSidebar,
		collapse,
		opensidebar,
		closesidebar,
	}
}
