import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { ProductsService } from 'services/products/products.service'
import { toastError } from 'utils/toastError'

import { IRequestToProducts, IResponseProducts } from './products.interface'

export const fetchingProducts = createAsyncThunk<
	IResponseProducts,
	IRequestToProducts
>('products/fetching', async ({ limit, sort, page, store_id }, thunkApi) => {
	try {
		const response = await ProductsService.getAll({
			limit,
			store_id,
			sort,
			page,
		})
		return response.data
	} catch (error) {
		toastError(error)
		return thunkApi.rejectWithValue(error)
	}
})

export const clearStore = createAsyncThunk(
	'products/clear',
	async ({ id }: { id: number }, thunkApi) => {
		try {
			const response = await ProductsService.clearStore({ id })
			return response
		} catch (error) {
			toastError(error)
			return thunkApi.rejectWithValue(error)
		}
	}
)

export const deleteSingleProduct = createAsyncThunk(
	'products/delete',
	async ({ id }: { id: number }, thunkApi) => {
		try {
			const response = await ProductsService.delete({ id })
			toast.success('Продукт был удален!')
			return response
		} catch (error) {
			toastError(error)
			throw thunkApi.rejectWithValue(error)
		}
	}
)

export const newProducts = createAsyncThunk(
	'addNewProducts',
	async ({ data }: { data: any }, thunkApi) => {
		try {
			const response = await ProductsService.addNewProducts(data)
			toast.success('Товары успешно добавлены!')
			return response
		} catch (error) {
			toastError()
			return thunkApi.rejectWithValue(error)
		}
	}
)
