import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { AdminstrationService } from 'services/adminstration/adminstration.service'

export const getAdminstrationData = createAsyncThunk(
	'getAdminstrationData',
	async (data, thunkApi) => {
		try {
			const response = await AdminstrationService.getData()
			return response
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)

export const updateAdminstrationData = createAsyncThunk(
	'updateAdminstrationData',
	async ({data}: {data: any }, thunkApi) => {
		try {
			const response = await AdminstrationService.updateData(data)
			toast.success('Данные успешно изменены!')
			return response
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)
