import axios from 'axios'
import Cookies from 'js-cookie'

// export const baseURL = 'https://test.paydali.uz/'
export const baseURL = window.location.origin
const token = Cookies.get('token')
export const axiosClassic = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'Application/json',
	},
})

export const axiosAPI = axios.create({
	baseURL: baseURL,
	headers: {
		Authorization: 'Bearer ' + token,
	},
})
