import { FC } from 'react'

import './Heading.scss'
import { IHeading } from './heading.interface'

const Heading: FC<IHeading> = ({ icon, title, color, btns = () => <></> }) => {
	return (
		<div className="heading">
			<div className="heading__left">
				<span className={'heading__icon ' + color}>
					<i className={icon}></i>
				</span>
				<span className="heading__title">{title}</span>
			</div>
			<div className="heading__right">{btns()}</div>
		</div>
	)
}
export default Heading
