export const usersSegmentOptions = [
	{
		label: 'Все',
		value: '',
	},
	{
		label: 'Активный',
		value: 'active',
	},
	{
		label: 'Неактивный',
		value: 'inactive',
	},{
		label: 'Отказано',
		value: 'reject',
	},
]
