import { Segmented } from 'antd'
import Main from 'components/layout/main/Main'
import MyPagination from 'components/ui/MyPagination/MyPagination'
import Heading from 'components/ui/heading/Heading'
import UsersTable from 'components/ui/usersTable/UsersTable'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useEffect, useState } from 'react'
import { usersSegmentOptions } from 'shared/usersSegmentOptions'

const Users: FC = () => {
	const [selectedType, setSelectedType] = useState('')
	const [page, setPage] = useState<number>(1)
	const { getAllClients } = useActions()
	const { users, isLoadingUsers, pagination } = useTypedSelector(
		(state) => state.user
	)

	useEffect(() => {
		getAllClients({ status: selectedType, search: '', page: page })
	}, [selectedType, page, getAllClients])

	return (
		<div>
			<Heading
				icon="bx bx-user"
				title="Все клиенты"
				color="bg-blue-300 text-blue-600"
			/>
			<br />
			<Main>
				<div className="flex justify-between items-center gap-x-12">
					<Segmented
						defaultValue={selectedType}
						onChange={(e) => setSelectedType(e.toString())}
						options={usersSegmentOptions}
					/>

					<h2 className="text-gray-600">
						<b>Общий:</b> {pagination.count}
					</h2>
				</div>
			</Main>
			<Main>
				<UsersTable isLoading={isLoadingUsers} data={users} />
				<br />
				<br />
				<MyPagination
					defaultCurrent={page}
					total={pagination.total}
					pageSize={pagination.per_page}
					onChange={(page) => {
						setPage(page)
					}}
				/>
				<br />
			</Main>
		</div>
	)
}
export default Users
