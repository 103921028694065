import {Input, Spin} from 'antd'
import useActions from 'hooks/useActions'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { uploadImage } from 'utils/uploadImage'

import { Button } from '../../../../../components/ui/Button'

import './NewProductForm.scss'
import { INewProductProps } from './newproduct.interface'

const NewProductForm: FC<INewProductProps> = (props) => {
	const { isLoading, singleSelectedItem } = useTypedSelector(
		(store) => store.store
	)
	const [loading, setLoading] = useState(false)
	const [storeData, setStoreData] = useState({
		name: '',
		phone: '',
		image: 'https://picsum.photos/400',
		description: '',
		instagram: '',
		telegram: '',
	})
	const { description, instagram, name, phone, telegram } = storeData
	const { addNewStore } = useActions()
	const onSubmit = (e) => {
		e.preventDefault()
		addNewStore({
			data: {
				...storeData,
				instagram: 'https://instagram.com/' + storeData.instagram,
				telegram: 'https://t.me/' + storeData.telegram,
			},
		})

	}

	const onChangePhotoInput = (e) => {
		setLoading(true)
		uploadImage([...e.target.files]).then((res) => {
			setStoreData({ ...storeData, image: res.data[0].url })
			setLoading(false)
		})
	}

	return (
		<Spin spinning={loading}>
			<form onSubmit={onSubmit} className="newProduct-form mt-4">
				<label>
					<span>Название магазина</span>
					<input
						value={name}
						onChange={(e) => setStoreData({ ...storeData, name: e.target.value })}
						name="name"
						type="text"
						placeholder=""
						required
					/>
				</label>
				<label>
					<span>Телефон</span>
					<input
						value={phone}
						onChange={(e) =>
							setStoreData({ ...storeData, phone: e.target.value })
						}
						type="tel"
						required
					/>
				</label>
				<label>
					<span>Логотип магазина</span>
					<input
						onChange={onChangePhotoInput}
						className="file"
						type="file"
						required
					/>
				</label>
				<label>
					<span>Описание</span>
					<textarea
						value={description}
						onChange={(e) =>
							setStoreData({ ...storeData, description: e.target.value })
						}
					></textarea>
				</label>
				<label>
					<span>Инстаграм</span>
					<Input
						addonBefore="instagram.com/"
						value={instagram}
						onChange={(e) =>
							setStoreData({ ...storeData, instagram: e.target.value })
						}
						type="text"
					/>
				</label>
				<label>
					<span>Телеграм</span>
					<Input
						addonBefore="t.me/"
						value={telegram}
						onChange={(e) =>
							setStoreData({ ...storeData, telegram: e.target.value })
						}
						type="text"
					/>
				</label>
				<Button type="submit" className="mt-2 py-2" loading={isLoading || loading}>
					Сохранить
				</Button>
			</form>
		</Spin>
	)
}
export default NewProductForm
