import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {toastError} from 'utils/toastError'

import {StoreService} from '../../services/store/store.service'
import {StoreRequestParams, StoreResponse,} from '../../shared/types/store.types'

import {IUpdateStoreRequestData, IUpdateStoreResponse,} from './store.interface'

export const fetchingStores = createAsyncThunk<
	StoreResponse,
	StoreRequestParams
>('stores/fetch', async ({ page, search }, thunkApi) => {
	try {
		return await StoreService.fetchStores({page, search})
	} catch (error) {
		return thunkApi.rejectWithValue(error)
	}
})

export const addNewStore = createAsyncThunk(
	'stores/add',
	async ({ data }: { data: any }, thunkApi) => {
		try {
			const response = await StoreService.newStore(data)
			// thunkApi.dispatch(fetchingStores({ page: 1 }))
			toast.success('Магазин успешно добавлен! ')
			console.log(response)
			thunkApi.dispatch(fetchingStores({
				page: JSON.parse(localStorage.getItem('store_page')), search: ""
			}))
			return response
		} catch (error) {
			toastError(error)
			toastError('')
			return thunkApi.rejectWithValue(error)
		}
	}
)

export const updateStore = createAsyncThunk<
	IUpdateStoreResponse,
	IUpdateStoreRequestData
>('stores/update', async (data, thunkApi) => {
	try {
		const response = await StoreService.updateStore(data)
		toast.success('Завершено успешно')
		return response
	} catch (error) {
		toastError('Произошла ошибка! Пожалуйста, попробуйте еще раз.')
		return thunkApi.rejectWithValue(error)
	}
})

export const getSingleStore = createAsyncThunk(
	'getSingleStore',
	async ({ id }: { id: number }, thunkApi) => {
		try {
			return await StoreService.getSingleStore(id)
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)
