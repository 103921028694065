import { Link } from 'react-router-dom'

import './NotFoundPage.scss'

const NotFoundPage = () => {
	return (
		<>
			<section className="page_404">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 ">
							<div className="col-sm-10 col-sm-offset-1  text-center">
								<div className="four_zero_four_bg"></div>

								<div className="contant_box_404">
									<h3 className="h2">Страница не найдена!</h3>

									<p>Пожалуйста, вернитесь на домашнюю страницу!</p>

									<Link to="/" className="link_404">
										Go to Home
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default NotFoundPage
