import { axiosAPI } from 'api/axios.interceptors'
import { IRequestToProducts } from 'store/products/products.interface'

export const ProductsService = {
	async getAll({ page, limit, store_id, sort }: IRequestToProducts) {
		const response = await axiosAPI.get('/api/products', {
			params: {
				limit,
				page,
				store_id,
				sort,
			},
		})
		return response
	},
	async clearStore({ id }: { id: number }) {
		const response = await axiosAPI.delete(`/api/stores/clear/${id}`)
		return response
	},
	async delete({ id }: { id: number }) {
		const response = await axiosAPI.delete(`/api/products/${id}`)
		return response
	},

	async addNewProducts(data) {
		const response = await axiosAPI.post('/api/products', data)
		return response
	},
}
