import { FC } from 'react'

import { sidebarData } from '../sidebar.data'

import './MenuContainer.scss'
import MenuItem from './MenuItem/MenuItem'

interface IMenuContainerProps {
	reportLength?: number
}

const MenuContainer: FC<IMenuContainerProps> = ({ reportLength }) => {
	return (
		<ul className="menu-container">
			{sidebarData?.map((item) => {
				return (
					<MenuItem key={item.path} reportLength={reportLength} {...item} />
				)
			})}
		</ul>
	)
}
export default MenuContainer
