export const sidebarData = [
	{
		title: 'Общий',
		label: 'Магазины',
		path: '/',
		icon: 'bx bx-store',
	},
	{
		label: 'Клиенты',
		path: null,
		icon: 'bx bxs-user-detail',
		subitems: [
			{
				label: 'Все клиенты',
				path: '/users/all',
			},
			{
				label: 'Клиенты под вопросом',
				path: '/users/request',
				dot: true,
			},
		],
	},
	{
		label: 'Администрация',
		path: '/administrator',
		icon: 'bx bx-shield-minus',
	},
]
