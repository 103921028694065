import { Navigate, Outlet } from 'react-router-dom'

import { useTypedSelector } from '../../hooks/useTypedSelector'

const AuthRequire = () => {
	const { user } = useTypedSelector((state) => state.user)
	if (user) return <Outlet />
	if (!user) return <Navigate to={'/auth'} replace={true} />
}
export default AuthRequire
