import { configureStore } from '@reduxjs/toolkit'

import { rootReducer } from './rootReducer'

const store = configureStore({
	reducer: rootReducer,
	devTools: true,
})

export type TypeRootState = ReturnType<typeof store.getState>
export default store
