import { Pagination } from 'antd'
import { FC } from 'react'

import { IMyPaginationProps } from './mypagination.interface'

const MyPagination: FC<IMyPaginationProps> = ({ ...rest }) => {
	return (
		<center className="my-4">
			<Pagination {...rest} />
		</center>
	)
}
export default MyPagination
