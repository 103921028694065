import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {UserService} from 'services/user/user.service'
import {IUsersRequest, IUsersResponse} from 'shared/types/users.interface'

import {AuthService} from '../../services/auth/auth.service'

import {IPhonePassword, IUserState} from './user.interface'

// login
export const login = createAsyncThunk<IUserState, IPhonePassword>(
	'auth/login',
	async ({ phone, password }, thunkApi) => {
		try {
			const response = await AuthService.login(phone, password)
			toast.success('Авторизация успешна!!')

			return response.data
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)

// logout
export const logout = createAsyncThunk('auth/logout', async (_, thunkApi) => {
	await AuthService.logout()
})

// checkAuth
export const checkAuth = createAsyncThunk('auth/check', async (_, thunkApi) => {
	try {
		const response = await AuthService.check()
		return response.data
	} catch (error) {
		thunkApi.dispatch(logout())
		return thunkApi.rejectWithValue(error)
	}
})

// get all clients
export const getAllClients = createAsyncThunk<IUsersResponse, IUsersRequest>(
	'users/getAll',
	async ({ status, search }, thunkApi) => {
		try {
			return await UserService.getAll({status, search})
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)
