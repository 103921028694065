import { useLogin } from 'hooks/useLogin'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTypedSelector } from '../../../../hooks/useTypedSelector'

export const useAuthRedirect = () => {
	const { user } = useTypedSelector((store) => store.user)
	const navigate = useNavigate()

	useEffect(() => {
		if (user) navigate('/', { replace: true })
	}, [user, navigate])
}
