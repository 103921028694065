import { FC } from 'react'

import './Modal.scss'
import { IModalProps } from './modal.interface'

const Modal: FC<IModalProps> = (props) => {
	const { children, onCancel, visible, title, className, footer } = props
	if (!visible) return <></>
	return (
		<div className="modal-overlay">
			<div className={'modal ' + className}>
				<header className="modal__header">
					<h2>{title}</h2>
					<span onClick={onCancel}>
						<i className="bx bx-x"></i>
					</span>
				</header>
				<main className="modal__content">{children}</main>
				{footer ? <footer className="modal__footer">{footer}</footer> : null}
			</div>
		</div>
	)
}
export default Modal
