import {axiosAPI} from 'api/axios.interceptors'
import {IUsersRequest} from 'shared/types/users.interface'

export const UserService = {
	async getAll({ status, search }: IUsersRequest) {
		return await axiosAPI.get('/api/users', {
			params: {
				search,
				status,
			},
		})
	},
}
