import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {ReportsService} from 'services/reports/reports.service'

import {IReportsRequest, IReportsResponse} from './reports.interface'

export const getAllReports = createAsyncThunk<
	IReportsResponse,
	IReportsRequest
>('getAllReports', async ({ page }, thunkApi) => {
	try {
		return await ReportsService.getAll({page})
	} catch (error) {
		return thunkApi.rejectWithValue(error)
	}
})

export const approveReport = createAsyncThunk(
	'approveReport',
	async ({ id }: { id: number }, thunkApi) => {
		try {
			const response = await ReportsService.approve({ id })
			thunkApi.dispatch(getAllReports({ page: 1 }))
			toast.success('Запрос клиента получен!')
			return response
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)

export const declineReport = createAsyncThunk(
	'declineReport',
	async ({ id }: { id: number }, thunkApi) => {
		try {
			const response = await ReportsService.decline({ id })
			thunkApi.dispatch(getAllReports({ page: 1 }))
			toast.success('Запрос клиента отменен!')
			return response
		} catch (error) {
			return thunkApi.rejectWithValue(error)
		}
	}
)
